import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: Now that my child is a teenager, they are too old for me to let them know that I love them." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Even though your teenager is no longer a child, it is still important to
        regularly show your teenager that they are loved and respected. Choose ways to show affection that suit you and
        your teenager. See <GoTo to="/m1-connect/06-show-affection" mdxType="GoTo">Show affection and encouragement</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: The best way to have a conversation with my teenager is when I am busy doing something else so they don't feel like they are being interrogated." fallbackResponse={<p>
        The correct answer is: <b>False</b>. It is best to minimise distractions and listen attentively to your
        teenager. Good listening helps to encourage more conversation from your teenager. See{' '}
        <GoTo to="/m1-connect/10-take-time-to-talk" mdxType="GoTo">Take the time to talk</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: I respect my teenager's opinion, even if I disagree with their point of view." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Teenagers will be more likely to listen to and respect your opinion if you
        show respect for their viewpoint. See{' '}
        <GoTo to="/m1-connect/12-talking-to-brickwall" mdxType="GoTo">It's like talking to a brick wall!</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: Validating the feelings my teenager is telling me in conversation will help them feel understood." fallbackResponse={<p>
        The correct answer is: <b>True</b>. It is important to acknowledge and show empathy for what your teenager is
        going through. This also lets them know that you care about their feelings. See{' '}
        <GoTo to="/m1-connect/14-identify-validate-understand" mdxType="GoTo">Identify, validate & understand</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: It is a good idea to ask my teenager and explore with them what is behind the emotions they are expressing." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Encouraging your teenager to tell you what's going on helps them understand
        what they are feeling and learn to manage the emotions effectively. See{' '}
        <GoTo to="/m1-connect/14-identify-validate-understand" mdxType="GoTo">Identify, validate & understand</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage smallGridSize={8} gridSize={4} src="/images/shared/Oriental_quiz.svg" alt="thinking man" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      